import React from 'react';
import PropTypes from 'prop-types';
import Hero from '@fuww/library/src/Hero';
import {
  DesktopOnlyContainer as DefaultDesktopOnlyContainer,
  MobileOnlyContainer as DefaultMobileOnlyContainer,
}
from '@fuww/library/src/ImageContainer';
import breakpoints from '@fuww/library/src/Theme/breakpoints';
import ImageWithUrls from '../ImageWithUrls';

export const defaultHeader = {
  default: {
    mobile: [
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=34,quality=10,width=42/https://fashionunited.com/img/upload/2024/05/06/1-0l9r6j8n-2024-05-06.png',
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=288,quality=70,width=360/https://fashionunited.com/img/upload/2024/05/06/1-0l9r6j8n-2024-05-06.png',
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=576,quality=70,width=720/https://fashionunited.com/img/upload/2024/05/06/1-0l9r6j8n-2024-05-06.png',
    ],
    desktop: [
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=8,quality=10,width=42/https://fashionunited.com/img/upload/2024/05/06/hero-image-vyd5e1bo-2024-05-06.png',
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=277,quality=70,width=1350/https://fashionunited.com/img/upload/2024/05/06/hero-image-vyd5e1bo-2024-05-06.png',
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=526,quality=70,width=2560/https://fashionunited.com/img/upload/2024/05/06/hero-image-vyd5e1bo-2024-05-06.png',
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=1052,quality=70,width=5120/https://fashionunited.com/img/upload/2024/05/06/hero-image-vyd5e1bo-2024-05-06.png',
    ],
  },
  premium: {
    mobile: [
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=34,quality=10,width=42/https://fashionunited.com/img/upload/2024/05/06/1-0l9r6j8n-2024-05-06.png',
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=288,quality=70,width=360/https://fashionunited.com/img/upload/2024/05/06/1-0l9r6j8n-2024-05-06.png',
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=576,quality=70,width=720/https://fashionunited.com/img/upload/2024/05/06/1-0l9r6j8n-2024-05-06.png',
    ],
    desktop: [
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=8,quality=10,width=42/https://media.fashionunited.com/logos/company_profile_premium_default_.etMJRMn3p6knFKdLj.jpg',
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=277,quality=70,width=1350/https://media.fashionunited.com/logos/company_profile_premium_default_.etMJRMn3p6knFKdLj.jpg',
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=526,quality=70,width=2560/https://media.fashionunited.com/logos/company_profile_premium_default_.etMJRMn3p6knFKdLj.jpg',
      'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=1052,quality=70,width=5120/https://media.fashionunited.com/logos/company_profile_premium_default_.etMJRMn3p6knFKdLj.jpg',
    ],
  },
};

export const MobileOnlyImage = ({ title, imageUrls, MobileOnlyContainer }) => (
  <MobileOnlyContainer>
    <ImageWithUrls
      alt={title}
      sizes="100vw"
      imageUrls={imageUrls}
      srcSet={`${imageUrls[1]
      } 360w, ${imageUrls[2]
      } 720w`}
      preload
      preloadMediaQuery={`(max-width: ${breakpoints.sm}px)`}
      loadStrategy="instant"
      finalFetchpriority="high"
      placeholderFetchpriority="high"
    />
  </MobileOnlyContainer>
);

MobileOnlyImage.propTypes = {
  title: PropTypes.string,
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  MobileOnlyContainer: PropTypes.elementType,
};

MobileOnlyImage.defaultProps = {
  title: null,
  MobileOnlyContainer: DefaultMobileOnlyContainer,
};

export const DesktopOnlyImage = ({
  title, imageUrls, DesktopOnlyContainer, disableRoundedCorners,
}) => (
  <DesktopOnlyContainer>
    <ImageWithUrls
      alt={title}
      sizes="100vw"
      imageUrls={imageUrls}
      srcSet={`${imageUrls[1]
      } 1350w, ${imageUrls[2]
      } 2560w, ${imageUrls[3]
      } 5120w`}
      preload
      preloadMediaQuery={`(min-width: ${breakpoints.sm}px)`}
      loadStrategy="on-visible"
      finalFetchpriority="high"
      placeholderFetchpriority="high"
      disableRoundedCorners={disableRoundedCorners}
    />
  </DesktopOnlyContainer>
);

DesktopOnlyImage.propTypes = {
  title: PropTypes.string,
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  DesktopOnlyContainer: PropTypes.elementType,
  disableRoundedCorners: PropTypes.bool,
};

DesktopOnlyImage.defaultProps = {
  title: null,
  DesktopOnlyContainer: DefaultDesktopOnlyContainer,
  disableRoundedCorners: false,
};

const HeaderImage = ({
  profile,
  MobileOnlyContainer,
  DesktopOnlyContainer,
}) => {
  const {
    page: {
      premium,
      imageUrls,
      mobileImageUrls,
      title,
    },
  } = profile;

  const commonImageUrls = premium
    ? defaultHeader.premium
    : defaultHeader.default;

  const mobileHeaderUrlsWithFallback = mobileImageUrls[0]
    ? mobileImageUrls
    : imageUrls.slice(4, 7);

  const mobileHeaderUrls = premium && mobileHeaderUrlsWithFallback[0]
    ? mobileHeaderUrlsWithFallback
    : commonImageUrls.mobile;

  const desktopHeaderUrls = premium && imageUrls[0]
    ? imageUrls.slice(0, 4)
    : commonImageUrls.desktop;

  const imageTitle = `Company Profile header ${title}`;

  return (
    <Hero
      image={(
        <>
          <MobileOnlyImage
            title={imageTitle}
            imageUrls={mobileHeaderUrls}
            MobileOnlyContainer={MobileOnlyContainer}
          />
          <DesktopOnlyImage
            title={imageTitle}
            imageUrls={desktopHeaderUrls}
            DesktopOnlyContainer={DesktopOnlyContainer}
            disableRoundedCorners
          />
        </>
      )}
    />
  );
};

HeaderImage.propTypes = {
  profile: PropTypes.shape({
    page: PropTypes.shape({
      premium: PropTypes.bool,
      imageUrls: PropTypes.arrayOf(PropTypes.string),
      mobileImageUrls: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
    }),
  }).isRequired,
  MobileOnlyContainer: PropTypes.elementType,
  DesktopOnlyContainer: PropTypes.elementType,
};

HeaderImage.defaultProps = {
  MobileOnlyContainer: DefaultMobileOnlyContainer,
  DesktopOnlyContainer: DefaultDesktopOnlyContainer,
};

export default HeaderImage;
